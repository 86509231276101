import axios from 'axios'
import { NewAxiosResponse } from './axios'
import { message as antdMessage } from 'antd'

type UserInfo = {
  account: string
  password?: string
  token: string
  remeber: boolean
  saveTime: string
}

export const getUserInfo = (): UserInfo => {
  const userString = localStorage.getItem('battery_admin_user') || '{}'
  return userString ? JSON.parse(userString) : null
}

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

let httpClient = axios.create({
  // baseURL: 'http://192.168.3.146:8080/data',
  baseURL: '/data',
  timeout: 6000,
  withCredentials: true,
})

httpClient.interceptors.request.use(
  (config) => {
    if (getUserInfo()) {
      config.headers['token'] = getUserInfo()?.token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

httpClient.interceptors.response.use(
  (response) => {
    const res = response.data
    const { status, message } = res
    if (status === 200) {
      return Promise.resolve(res as NewAxiosResponse)
    } else if (status === 403) {
      antdMessage.error(message)
      const hrefList = window.location.href.split('/')
      if (hrefList[hrefList.length - 1] !== 'login') {
        window.location.href = '/login'
      }
    } else {
      antdMessage.error(message)
    }
    return Promise.resolve(res as NewAxiosResponse)
  },
  (error) => {
    antdMessage.error(error.response.data.message)
    return Promise.reject(error)
  },
)

export const GET = (path: any, config = {}) => httpClient.get(path, config)

export const POST = (path: any, data = {}, config = {}) =>
  httpClient.post(`${path}`, data, config)

import React, { FC, ReactNode, useEffect, useState } from 'react'
import { Breadcrumb, Button, Flex, Layout, Menu } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  AccountConfig,
  NavConfig,
  NavConfigItem,
} from '../utils/router/nav_config'
import Login from '../pages/login'
import { getUserInfo, POST } from '../service/request'
import './style.css'
import { MenuInfo } from 'rc-menu/lib/interface'
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb'

const { Sider } = Layout

const Frame: FC<{ children: ReactNode }> = ({ children }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { pathname } = location
  const userInfo = getUserInfo()
  const [breadcrumbText, setBreadcrumbText] = useState<ItemType[]>([])
  const [pages, setPages] = useState<NavConfigItem[]>([
    ...NavConfig,
    AccountConfig,
  ])
  const verifyToken = async () => {
    if (userInfo.saveTime) {
      const { saveTime, token } = userInfo
      // token本地存储七天后更新
      if (Number(saveTime) + 7 * 24 * 60 * 60 * 1000 > new Date().getTime()) {
        return
      }

      POST('/user/checkToken', { token }, {})
        .then((res) => {
          if (res.status === 200) {
            navigate(`/login?back=${true}`)
          }
        })
        .catch((err) => {})
    }
  }

  const logout = () => {
    POST('/user/logout', { token: userInfo?.token }, {})
      .then((res) => {
        if (res.status === 200) {
          navigate(`/login?back=${false}`)
        }
      })
      .catch((err) => {})
  }

  const navPage = (e: MenuInfo) => {
    navigate('/' + e.keyPath.join('/'))
  }

  useEffect(() => {
    verifyToken()
  }, [])

  useEffect(() => {
    if (userInfo?.account === 'admin') {
      setPages([...NavConfig, AccountConfig])
    } else {
      setPages([...NavConfig])
    }
  }, [userInfo?.account])

  useEffect(() => {
    const navItem = [...NavConfig, AccountConfig].find(
      (item) => item.url === pathname,
    )
    setBreadcrumbText([{ title: navItem?.label as string }])
  }, [pathname])

  return (
    <div className="frame">
      {pathname === '/login' ? (
        <Login />
      ) : (
        <Layout>
          <Flex className="frame_header">
            <div className="demo-logo" />
            <Flex align="center" justify="space_between">
              <div className="frame_account">
                {userInfo?.account || '用户名'}
              </div>
              <Button type="link" size="middle" onClick={() => logout()}>
                退出
              </Button>
            </Flex>
          </Flex>
          <Layout className="frame_body">
            <Sider width={150} style={{ background: '#fff', color: '#131824' }}>
              <Menu
                onClick={(e) => navPage(e)}
                items={pages}
                defaultSelectedKeys={['battery_info']}
              />
            </Sider>
            <Layout style={{ padding: '0 16px 24px', background: '#e2e1e4' }}>
              <Breadcrumb className="frame_breadcrumb" items={breadcrumbText} />
              <div className="frame_content">{children}</div>
            </Layout>
          </Layout>
        </Layout>
      )}
    </div>
  )
}

export default Frame

import { ReactNode } from 'react'
import BatteryInfo from '../../pages/battery_info'
import ManufacturerManage from '../../pages/manufacturer_manage'
import AccountManage from '../../pages/account_manage'
import { ItemType, MenuItemType } from 'antd/es/menu/interface'
import NominalCapacity from '../../pages/nominal_capacity'
export interface NavConfigItem extends MenuItemType {
  url: string
  element: ReactNode
}

export const NavConfig: NavConfigItem[] = [
  {
    key: 'battery_info',
    url: '/battery_info',
    label: '电池信息',
    element: <BatteryInfo />,
  },
  {
    key: 'manufaturer_management',
    url: '/manufaturer_management',
    label: '厂商信息',
    element: <ManufacturerManage />,
  },
  {
    key: 'nominal_capacity',
    url: '/nominal_capacity',
    label: '电池型号',
    element: <NominalCapacity />,
  },
]

export const AccountConfig: NavConfigItem = {
  key: 'account_management',
  url: '/account_management',
  label: '账号管理',
  element: <AccountManage />,
}

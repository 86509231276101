import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Button, Flex, Form, Input, message, Modal, Table } from 'antd'
import type { TableColumnsType } from 'antd'
import CreateManufacturer, {
  CreateManufacturerRef,
} from './create_manufacturer'
import { POST } from '../../service/request'
import '../../utils/styles/common.css'
import { useForm } from 'antd/es/form/Form'

interface ManfacturerItem {
  key: React.Key
  id: string
  name: string
  code: string
  address: string
  status: boolean
}

interface SearchForm {
  name?: string
  code?: string
}

const ManufacturerManage = () => {
  const [list, setList] = useState<ManfacturerItem[]>([])
  const [total, setTotal] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [pageNo, setPageNo] = useState<number>(1)
  const [editId, setEditId] = useState<string | undefined>('')
  const [formValues, setFormValues] = useState<SearchForm>({})
  const [scrollHeight, setScrollHeight] = useState<number>(240)

  const [searchForm] = useForm()
  const createManufacturerRef = useRef<CreateManufacturerRef>(null)

  const openManufacturerModal = (id?: string) => {
    setEditId(id)
    createManufacturerRef.current && createManufacturerRef.current.handleOpen()
  }

  const handleDelete = (data: ManfacturerItem) => {
    Modal.confirm({
      title: '删除',
      content: `确定要删除 ${data.code}(${data.name}) 吗？`,
      okText: '删除',
      okButtonProps: { danger: true },
      onOk: () => {
        POST('/company/delete', { ids: [data.id] })
          .then((res) => {
            if (res.status === 200) {
              message.success('删除成功')
              getList()
            }
          })
          .catch((err) => {})
      },
    })
  }

  const getList = (values?: SearchForm) => {
    setEditId('')
    POST('/company/query', {
      ...(values || formValues),
      pageSize,
      pageNo,
    })
      .then((res) => {
        if (res.status === 200) {
          const { data, total } = res.data
          setList(data)
          if (pageNo === 1) {
            setTotal(total as number)
          }
        }
      })
      .catch((err) => {})
  }

  const onSearch = async (values: any) => {
    setFormValues(values)
    getList(values)
  }

  const onTableChange = (e: any) => {
    setPageNo(e.current)
    setPageSize(e.pageSize)
  }

  useEffect(() => {
    getList()
  }, [pageSize, pageNo])

  useLayoutEffect(() => {
    const height = window.innerHeight
    setScrollHeight(height - 350)

    const resize = () => {
      const height = window.innerHeight
      setScrollHeight(height - 350)
    }
    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  const columns: TableColumnsType<ManfacturerItem> = [
    {
      title: '厂商名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '厂商编码',
      dataIndex: 'code',
      key: 'code',
    },
    // {
    //   title: '标准容量',
    //   dataIndex: 'capacity',
    //   key: 'capacity',
    //   width: 120,
    //   render: (capacity, redord) => (capacity ? `${capacity}mAH` : '/'),
    // },
    {
      title: '官网',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: '是否验证',
      dataIndex: 'status',
      key: 'status',
      render: (status, redord) => (status ? '已验证' : '未验证'),
    },
    {
      title: '操作',
      dataIndex: 'operation',
      render: (_, record) => (
        <Flex>
          <Button
            type="link"
            size="small"
            onClick={() => openManufacturerModal(record.id)}
          >
            编辑
          </Button>
          <Button type="link" size="small" onClick={() => handleDelete(record)}>
            删除
          </Button>
        </Flex>
      ),
    },
  ]

  return (
    <div>
      <Form
        name="customized_form_controls"
        layout="inline"
        form={searchForm}
        onFinish={onSearch}
      >
        <Form.Item name="name" label="厂商名称">
          <Input maxLength={26} />
        </Form.Item>
        <Form.Item name="code" label="厂商编码">
          <Input maxLength={3} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            搜索
          </Button>
        </Form.Item>
      </Form>
      <div className="button_line">
        <Button
          type="link"
          size="small"
          onClick={() => openManufacturerModal()}
        >
          新增厂商
        </Button>
      </div>
      <Table
        className="table_in_frame"
        columns={columns}
        dataSource={list}
        rowKey="id"
        pagination={{
          showSizeChanger: true,
          pageSize: pageSize,
          current: pageNo,
          showTotal: (total) => `共${total}条`,
          total,
        }}
        onChange={(e) => onTableChange(e)}
        scroll={{ y: scrollHeight }}
      />
      <CreateManufacturer
        id={editId}
        refresh={getList}
        modalRef={createManufacturerRef}
      />
    </div>
  )
}

export default ManufacturerManage

import React, {
  FC,
  RefObject,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { Button, Flex, Form, Input, message, Modal } from 'antd'
import { POST } from '../../service/request'
import { useForm } from 'antd/es/form/Form'
import { NewAxiosResponse } from '../../service/axios'

export interface CreateAccountRef {
  handleOpen: () => void
}

interface CreateAccountProps {
  id?: string
  refresh: () => void
  modalRef: RefObject<CreateAccountRef>
}

const CreateAccount: FC<CreateAccountProps> = (props) => {
  const { id, refresh, modalRef } = props
  const [accountForm] = useForm()
  const [visible, setVisible] = useState<boolean>(false)
  const [editPassword, setEditPassword] = useState<boolean>(false)

  const getAccount = async () => {
    POST('/user/queryOne', { id })
      .then((res) => {
        if (res.status === 200) {
          accountForm.setFieldsValue(res.data)
        }
      })
      .catch((error) => {})
  }

  const handleOpen = () => {
    setVisible(true)
  }

  const cancelEditPassword = () => {
    accountForm.setFieldValue('password', undefined)
    setEditPassword(false)
  }

  const handleConfirm = async () => {
    const info = await accountForm.validateFields()
    if (id) {
      POST('/user/update', { ...info, id })
        .then((res) => {
          reqSuccess(res)
        })
        .catch((err) => {})
    } else {
      POST('/user/add', info)
        .then((res) => {
          reqSuccess(res)
        })
        .catch((err) => {})
    }
  }
  const reqSuccess = (res: NewAxiosResponse) => {
    if (res.status === 200) {
      message.success(id ? '修改成功' : '新建成功')
      refresh()
      handleCancel()
    }
  }

  const handleCancel = () => {
    setVisible(false)
    accountForm.resetFields()
  }

  useImperativeHandle(modalRef, () => ({
    handleOpen,
  }))

  useEffect(() => {
    if (visible && id) {
      getAccount()
    }
  }, [visible, id])

  return (
    <Modal
      title={id ? '修改用户' : '新建用户'}
      open={visible}
      onOk={handleConfirm}
      onCancel={() => handleCancel()}
      width={400}
      destroyOnClose
    >
      <Form
        name="create_user"
        form={accountForm}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }}
      >
        <Form.Item
          name="account"
          label="用户名"
          rules={[{ required: true, message: '请输入用户名!' }]}
        >
          <Input disabled={!!id} maxLength={10} />
        </Form.Item>
        {id && !editPassword ? (
          <Form.Item label="密码">
            <Flex align="center">
              ********
              <Button type="link" onClick={() => setEditPassword(true)}>
                修改
              </Button>
            </Flex>
          </Form.Item>
        ) : (
          <Form.Item
            name="password"
            label="密码"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: id ? 16 : 12 }}
            rules={[{ required: true, message: '请输入6-12位密码!' }]}
          >
            <Flex>
              <Input />
              {id && (
                <Button type="link" onClick={() => cancelEditPassword()}>
                  取消
                </Button>
              )}
            </Flex>
          </Form.Item>
        )}
        <Form.Item name="name" label="使用者" rules={[{ required: true }]}>
          <Input maxLength={10} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CreateAccount

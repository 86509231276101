import React, {
  FC,
  RefObject,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { Checkbox, Form, Input, message, Modal } from 'antd'
import { POST } from '../../service/request'
import { useForm } from 'antd/es/form/Form'
import { NewAxiosResponse } from '../../service/axios'

export interface CreateManufacturerRef {
  handleOpen: () => void
}

interface CreateManufacturerProps {
  id?: string
  refresh: () => void
  modalRef: RefObject<CreateManufacturerRef>
}

const CreateManufacturer: FC<CreateManufacturerProps> = (props) => {
  const { id, refresh, modalRef } = props
  const [manufacturerForm] = useForm()
  const [visible, setVisible] = useState<boolean>(false)
  const [name, setName] = useState<string>('')

  const getManufacturer = async () => {
    POST('/company/queryOne', { id })
      .then((res) => {
        if (res.status === 200) {
          manufacturerForm.setFieldsValue(res.data)
          setName(res.data.name)
        }
      })
      .catch((err) => {})
  }

  const handleOpen = () => {
    setVisible(true)
  }

  const handleConfirm = async () => {
    const info = await manufacturerForm.validateFields()
    if (id) {
      POST('/company/update', { ...info, id })
        .then((res) => {
          reqSuccess(res)
        })
        .catch((err) => {})
    } else {
      POST('/company/add', info)
        .then((res) => {
          reqSuccess(res)
        })
        .catch((err) => {})
    }
  }

  const reqSuccess = (res: NewAxiosResponse) => {
    if (res.status === 200) {
      message.success(id ? '修改成功' : '新建成功')
      refresh()
      handleCancel()
    }
  }

  useImperativeHandle(modalRef, () => ({
    handleOpen,
  }))

  const handleCancel = () => {
    setVisible(false)
    manufacturerForm.resetFields()
  }

  useEffect(() => {
    if (visible && id) {
      getManufacturer()
    }
  }, [visible])

  return (
    <Modal
      title={id ? '修改厂商' : '新建厂商'}
      open={visible}
      onOk={handleConfirm}
      onCancel={() => handleCancel()}
      width={400}
      destroyOnClose
    >
      <Form
        name="create_manufacturer"
        form={manufacturerForm}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }}
      >
        {id ? (
          <Form.Item label="厂商名称">{name}</Form.Item>
        ) : (
          <Form.Item
            name="name"
            label="厂商名称"
            rules={[{ required: true, message: '请输入厂商!' }]}
          >
            <Input disabled={!!id} maxLength={10} />
          </Form.Item>
        )}
        <Form.Item
          name="code"
          label="厂商编码"
          rules={[{ required: false, message: '厂商编码!' }]}
        >
          <Input minLength={3} maxLength={3} />
        </Form.Item>

        {/* <Form.Item
          name="capacity"
          label="标准容量"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item> */}
        <Form.Item name="address" label="官网" rules={[{ required: false }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="status"
          valuePropName="checked"
          wrapperCol={{ offset: 6, span: 12 }}
          rules={[{ required: false }]}
        >
          <Checkbox>验证</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CreateManufacturer

import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Button, Form, Input, message, Table, Upload } from 'antd'
import type { TableColumnsType, UploadProps } from 'antd'
import { POST } from '../../service/request'
import '../../utils/styles/common.css'
import { useForm } from 'antd/es/form/Form'
import './style.css'

interface AccountItem {
  key: React.Key
  id: string
  account: string
  name: string
  password: string
}

interface SearchForm {
  code?: string
}

const BatteryInfo = () => {
  const [list, setList] = useState<AccountItem[]>([])
  const [total, setTotal] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [pageNo, setPageNo] = useState<number>(1)
  const [formValues, setFormValues] = useState<SearchForm>({})
  const [scrollHeight, setScrollHeight] = useState<number>(240)
  const [searchForm] = useForm()

  const userInfo = JSON.parse(
    localStorage.getItem('battery_admin_user') || '{}',
  )

  const getList = (values?: SearchForm) => {
    POST('/battery/query', {
      ...(values || formValues),
      pageSize,
      pageNo,
    })
      .then((res) => {
        if (res.status === 200) {
          const { data, total } = res.data
          setList(data)
          if (pageNo === 1) {
            setTotal(total as number)
          }
        }
      })
      .catch((err) => {})
  }

  const onSearch = async (values: any) => {
    setFormValues(values)
    getList(values)
  }

  const onTableChange = (e: any) => {
    setPageNo(e.current)
    setPageSize(e.pageSize)
  }

  useEffect(() => {
    getList()
  }, [pageNo, pageSize])

  useLayoutEffect(() => {
    const height = window.innerHeight
    setScrollHeight(height - 350)

    const resize = () => {
      const height = window.innerHeight
      setScrollHeight(height - 350)
    }
    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  const props: UploadProps = {
    name: 'file',
    action: '/data/battery/uploadExcel',
    headers: {
      authorization: 'authorization-text',
      token: userInfo?.token || '',
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name}上传成功`)
        getList()
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} 上传失败`)
      }
    },
  }

  const columns: TableColumnsType<AccountItem> = [
    {
      title: '电芯条码',
      dataIndex: 'code',
      key: 'code',
      width: 260,
      fixed: 'left',
      render: (item) => item || '/',
    },
    {
      title: '产地',
      dataIndex: 'producePlace',
      key: 'producePlace',
      width: 80,
      render: (item) => item || '/',
    },
    {
      title: '生产厂商',
      dataIndex: 'producer',
      key: 'producer',
      width: 100,
      render: (item) => item || '/',
    },
    {
      title: '箱号',
      dataIndex: 'type',
      key: 'type',
      width: 100,
      render: (item) => item || '/',
    },
    {
      title: '型号',
      dataIndex: 'boxNo',
      key: 'boxNo',
      width: 160,
      render: (item) => item || '/',
    },
    {
      title: '容量/Ah',
      dataIndex: 'capacity',
      key: 'capacity',
      width: 160,
      render: (item) => item || '/',
    },
    {
      title: '补电容量/mAh',
      dataIndex: 'supplement',
      key: 'supplement',
      width: 160,
      render: (item) => item || '/',
    },
    {
      title: '电压OCV3/V',
      dataIndex: 'ocv',
      key: 'ocv',
      width: 120,
      render: (item) => item || '/',
    },
    {
      title: '内阻ACR/mΩ',
      dataIndex: 'acr',
      key: 'acr',
      width: 120,
      render: (item) => item || '/',
    },
    {
      title: '内阻DCR/mΩ',
      dataIndex: 'dcr',
      key: 'dcr',
      width: 120,
      render: (item) => item || '/',
    },
    {
      title: 'K值/mV/h',
      dataIndex: 'k',
      key: 'k',
      width: 120,
      render: (item) => item || '/',
    },
    {
      title: '重量/g',
      dataIndex: 'weight',
      key: 'weight',
      width: 100,
      render: (item) => item || '/',
    },
    {
      title: '厚度/mm',
      dataIndex: 'gear',
      key: 'gear',
      width: 100,
      render: (item) => item || '/',
    },
    {
      title: '电芯宽度/mm',
      dataIndex: 'width',
      key: 'width',
      width: 120,
      render: (item) => item || '/',
    },
    {
      title: '栈板号',
      dataIndex: 'version',
      key: 'version',
      width: 260,
      render: (item) => item || '/',
    },
    {
      title: '档位',
      dataIndex: 'width',
      key: 'width',
      width: 100,
      render: (item) => item || '/',
    },
  ]

  return (
    <div>
      <Form
        name="customized_form_controls"
        layout="inline"
        form={searchForm}
        onFinish={onSearch}
      >
        <Form.Item name="code" label="电池条码">
          <Input maxLength={26} />
        </Form.Item>
        <Form.Item name="manufacturer" label="生产厂商">
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            搜索
          </Button>
        </Form.Item>
      </Form>
      <div className="button_line">
        <Upload {...props}>
          <Button type="link">批量新增</Button>
        </Upload>
      </div>
      <Table
        className="table_in_frame"
        columns={columns}
        dataSource={list}
        rowKey="id"
        pagination={{
          showSizeChanger: true,
          pageSize: pageSize,
          current: pageNo,
          showTotal: (total) => `共${total}条`,
          total,
        }}
        onChange={(e) => onTableChange(e)}
        scroll={{ y: scrollHeight }}
      />
    </div>
  )
}

export default BatteryInfo

import React, { FC, useEffect } from 'react'
import { Button, Checkbox, Flex, Form, FormProps, Input, message } from 'antd'
import './style.css'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { POST } from '../../service/request'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useForm } from 'antd/es/form/Form'

type LoginType = {
  account: string
  password: string
  remember?: boolean
}

const Login = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [loginForm] = useForm()

  const onLogin: FormProps<LoginType>['onFinish'] = async (values) => {
    POST('/user/login', values, {})
      .then((res) => {
        if (res.status === 200) {
          const back = searchParams.get('back')
          localStorage.setItem(
            'battery_admin_user',
            JSON.stringify({
              ...(values.remember ? values : {}),
              ...res.data,
              saveTime: new Date().getTime().toString(),
            }),
          )
          if (back) {
            navigate(-1)
          } else {
            navigate('./battery_info')
          }
        }
      })
      .catch((err) => {})
  }

  useEffect(() => {
    const userInfo = JSON.parse(
      localStorage.getItem('battery_admin_user') || '{}',
    )
    if (userInfo?.remember) {
      loginForm.setFieldsValue(userInfo)
    }
  }, [])

  return (
    <Flex className="login" justify="center" align="center">
      <div className="form_container">
        <Form
          name="login"
          form={loginForm}
          style={{ maxWidth: 360 }}
          initialValues={{ remember: true }}
          onFinish={onLogin}
        >
          <Form.Item<LoginType>
            name="account"
            rules={[{ required: true, message: '请输入用户名!' }]}
          >
            <Input prefix={<UserOutlined />} placeholder="用户名" />
          </Form.Item>

          <Form.Item<LoginType>
            name="password"
            rules={[{ required: true, message: '请输入密码!' }]}
          >
            <Input
              prefix={<LockOutlined />}
              type="password"
              placeholder="密码"
            />
          </Form.Item>

          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>记住密码</Checkbox>
          </Form.Item>
          <Form.Item>
            <Button block type="primary" htmlType="submit">
              登录
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Flex>
  )
}

export default Login

import React, { useEffect, useState } from 'react'
import Frame from './frame'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Login from './pages/login'
import {
  AccountConfig,
  NavConfig,
  NavConfigItem,
} from './utils/router/nav_config'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/lib/locale/zh_CN'
import { getUserInfo } from './service/request'

function App() {
  const userInfo = getUserInfo()

  const [pages, setPages] = useState<NavConfigItem[]>([
    ...NavConfig,
    AccountConfig,
  ])

  useEffect(() => {
    if (userInfo?.account === 'admin') {
      setPages([...NavConfig, AccountConfig])
    } else {
      setPages([...NavConfig])
    }
  }, [userInfo?.account])
  return (
    <BrowserRouter>
      <ConfigProvider
        locale={zhCN}
        theme={{
          token: {
            colorPrimary: '#1b94bc',
            colorTextBase: '#131824',
            colorLink: '#1b94bc',
          },
        }}
      >
        <Frame
          children={
            <Routes>
              <Route path="/login" element={<Login />} />
              {pages.map(({ url, label, element }) => {
                return <Route key={url} path={url} element={element} />
              })}

              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          }
        ></Frame>
      </ConfigProvider>
    </BrowserRouter>
  )
}

export default App

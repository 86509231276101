import React, {
  FC,
  RefObject,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { Form, Input, message, Modal } from 'antd'
import { POST } from '../../service/request'
import { useForm } from 'antd/es/form/Form'
import { NewAxiosResponse } from '../../service/axios'

export interface CreateCapacityRef {
  handleOpen: () => void
}

interface CreateCapacityProps {
  id?: string
  refresh: () => void
  modalRef: RefObject<CreateCapacityRef>
}

const CreateCapacity: FC<CreateCapacityProps> = (props) => {
  const { id, refresh, modalRef } = props
  const [capacityForm] = useForm()
  const [visible, setVisible] = useState<boolean>(false)

  const getCapacity = async () => {
    POST('/capacity/queryOne', { id })
      .then((res) => {
        if (res.status === 200) {
          capacityForm.setFieldsValue(res.data)
        }
      })
      .catch((err) => {})
  }

  const handleOpen = () => {
    setVisible(true)
  }

  const handleConfirm = async () => {
    const info = await capacityForm.validateFields()
    if (id) {
      POST('/capacity/update', { ...info, id })
        .then((res) => {
          reqSuccess(res)
        })
        .catch((err) => {})
    } else {
      POST('/capacity/add', info)
        .then((res) => {
          reqSuccess(res)
        })
        .catch((err) => {})
    }
  }

  const reqSuccess = (res: NewAxiosResponse) => {
    if (res.status === 200) {
      message.success(id ? '修改成功' : '新建成功')
      refresh()
      handleCancel()
    }
  }

  useImperativeHandle(modalRef, () => ({
    handleOpen,
  }))

  const handleCancel = () => {
    setVisible(false)
    capacityForm.resetFields()
  }

  useEffect(() => {
    if (visible && id) {
      getCapacity()
    }
  }, [visible])

  return (
    <Modal
      title={id ? '修改型号' : '新建型号'}
      open={visible}
      onOk={handleConfirm}
      onCancel={() => handleCancel()}
      width={400}
      destroyOnClose
    >
      <Form
        name="create_capacity"
        form={capacityForm}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }}
      >
        <Form.Item
          name="code"
          label="型号编码"
          rules={[{ required: true, message: '型号编码!' }]}
        >
          <Input disabled={!!id} maxLength={2} />
        </Form.Item>
        <Form.Item name="type" label="电池型号">
          <Input />
        </Form.Item>
        <Form.Item
          name="capacity"
          label="标准容量"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="company" label="厂商名称">
          <Input maxLength={10} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CreateCapacity

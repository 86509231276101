import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Button, Flex, Form, Input, message, Modal, Table } from 'antd'
import type { TableColumnsType } from 'antd'
import CreateCapacity, { CreateCapacityRef } from './create_capacity'
import { POST } from '../../service/request'
import '../../utils/styles/common.css'
import { useForm } from 'antd/es/form/Form'

interface CapacityItem {
  key: React.Key
  id: string
  code: string
  company: string
  capacity: string
}

interface SearchForm {
  name?: string
  code?: string
}

const NominalCapacity = () => {
  const [list, setList] = useState<CapacityItem[]>([])
  const [total, setTotal] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [pageNo, setPageNo] = useState<number>(1)
  const [editId, setEditId] = useState<string | undefined>('')
  const [formValues, setFormValues] = useState<SearchForm>({})
  const [scrollHeight, setScrollHeight] = useState<number>(240)

  const [searchForm] = useForm()
  const createCapacityRef = useRef<CreateCapacityRef>(null)

  const openCapacityModal = (id?: string) => {
    setEditId(id)
    createCapacityRef.current && createCapacityRef.current.handleOpen()
  }

  const handleDelete = (data: CapacityItem) => {
    Modal.confirm({
      title: '删除',
      content: `确定要删除型号编码为 ${data.code} 的数据吗？`,
      okText: '删除',
      okButtonProps: { danger: true },
      onOk: () => {
        POST('/capacity/delete', { ids: [data.id] })
          .then((res) => {
            if (res.status === 200) {
              message.success('删除成功')
              getList()
            }
          })
          .catch((err) => {})
      },
    })
  }

  const getList = (values?: SearchForm) => {
    setEditId('')
    POST('/capacity/query', {
      ...(values || formValues),
      pageSize,
      pageNo,
    })
      .then((res) => {
        if (res.status === 200) {
          const { data, total } = res.data
          setList(data)
          if (pageNo === 1) {
            setTotal(total as number)
          }
        }
      })
      .catch((err) => {})
  }

  const onSearch = async (values: any) => {
    setFormValues(values)
    getList(values)
  }

  const onTableChange = (e: any) => {
    setPageNo(e.current)
    setPageSize(e.pageSize)
  }

  useEffect(() => {
    getList()
  }, [pageSize, pageNo])

  useLayoutEffect(() => {
    const height = window.innerHeight
    setScrollHeight(height - 350)

    const resize = () => {
      const height = window.innerHeight
      setScrollHeight(height - 350)
    }
    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  const columns: TableColumnsType<CapacityItem> = [
    {
      title: '型号编码',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: '电池型号',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: '标准容量',
      dataIndex: 'capacity',
      key: 'capacity',
      render: (capacity, redord) => (capacity ? `${capacity}` : '/'),
    },
    {
      title: '厂商名称',
      dataIndex: 'company',
      key: 'company',
      render: (company, redord) => (company ? `${company}` : '/'),
    },
    {
      title: '操作',
      dataIndex: 'operation',
      render: (_, record) => (
        <Flex>
          <Button
            type="link"
            size="small"
            onClick={() => openCapacityModal(record.id)}
          >
            编辑
          </Button>
          <Button type="link" size="small" onClick={() => handleDelete(record)}>
            删除
          </Button>
        </Flex>
      ),
    },
  ]

  return (
    <div>
      <Form
        name="customized_form_controls"
        layout="inline"
        form={searchForm}
        onFinish={onSearch}
        className="search_form"
      >
        <Form.Item name="company" label="厂商名称">
          <Input maxLength={26} />
        </Form.Item>
        <Form.Item name="type" label="电池型号">
          <Input />
        </Form.Item>
        <Form.Item name="code" label="型号编码">
          <Input maxLength={2} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            搜索
          </Button>
        </Form.Item>
      </Form>
      <div className="button_line">
        <Button type="link" size="small" onClick={() => openCapacityModal()}>
          新增型号
        </Button>
      </div>
      <Table
        className="table_in_frame"
        columns={columns}
        dataSource={list}
        rowKey="id"
        pagination={{
          showSizeChanger: true,
          pageSize: pageSize,
          current: pageNo,
          showTotal: (total) => `共${total}条`,
          total,
        }}
        onChange={(e) => onTableChange(e)}
        scroll={{ y: scrollHeight }}
      />
      <CreateCapacity
        id={editId}
        refresh={getList}
        modalRef={createCapacityRef}
      />
    </div>
  )
}

export default NominalCapacity
